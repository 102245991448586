import { useVideoQualityLevel } from '/machinery/useVideoQualityLevel'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import { mergeRefs } from '/machinery/mergeRefs'

export function useBackgroundVideo({ sources, onNotAllowed }) {
  const videoRef = React.useRef(null)
  const { src, ref: qualityRef } = useVideoQualityLevel({ sources })
  const { ref: visibilityRef, isInViewport } = useIsInViewport()

  React.useEffect(
    () => {
      videoRef.current.defaultPlaybackRate = 0.5
      if (isInViewport) {
        playSafe(videoRef.current)
      } else {
        videoRef.current.pause()
      }
    },
    [isInViewport]
  )

  return { src, ref: mergeRefs(videoRef, visibilityRef, qualityRef) }

  function playSafe(video) {
    return Promise.resolve(video.play()).catch(handleError)
  }

  function handleError(/** @type {DOMException} */ e) {
  // This event fires on e.g. low power mode on iOS Safari:
    if (e.name === 'NotAllowedError') {
      onNotAllowed(true)
    }
  }
}
