import { useBackgroundVideo } from '/machinery/useBackgroundVideo'

import styles from './BackgroundVideo.css'

export function BackgroundVideo({ sources, layoutClassName = undefined, poster }) {
  const [suspended, setSuspended] = React.useState(false)
  const { src, ref } = useBackgroundVideo({ sources, onNotAllowed: setSuspended })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <video
        loop
        muted
        autoPlay
        playsInline
        controlsList="nodownload"
        disablePictureInPicture
        className={styles.video}
        {...{ src, ref, poster }}
      />
      <div className={cx(styles.fallbackBackground, suspended && styles.isSuspended)} />
    </div>
  )
}
